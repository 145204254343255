const POPULATE_USER = "LOGIN_USER"
const LOGOUT_USER = "LOGOUT_USER"
const LOADING = "LOADING"
const LOADING_COMPLETE = "LOADING_COMPLETE"
const ADD_BUSINESS = "ADD_BUSINESS"
const POPULATE_BUSINESSES = "POPULATE_BUSINESSES"
const VALIDATE_PAYMENT = "VALIDATE_PAYMENT"
const ADD_REGULAR_EVENT = "ADD_REGULAR_EVENT"
const EDIT_REGULAR_EVENT = "EDIT_REGULAR_EVENT"
const ADD_IRREGULAR_EVENT = "ADD_IRREGULAR_EVENT"
const EDIT_IRREGULAR_EVENT = "EDIT_IRREGULAR_EVENT"
const DELETE_REGULAR_EVENT = "DELETE_REGULAR_EVENT"
const DELETE_IRREGULAR_EVENT = "DELETE_IRREGULAR_EVENT"
const SET_REGULAR_EVENTS = "SET_REGULAR_EVENTS"
const SET_IRREGULAR_EVENTS = "SET_IRREGULAR_EVENTS"
const CLEAR_EVENTS = "CLEAR_EVENTS"
const ADD_BUSINESS_SERVICE = "ADD_BUSINESS_SERVICE"
const ADD_AUTHORIZED_SERVICE = "ADD_AUTHORIZED_SERVICE"
const ADD_CONNECTED_PAGE = "ADD_CONNECTED_PAGE"
const REMOVE_CONNECTED_PAGE = "REMOVE_CONNECTED_PAGE"


export {
    POPULATE_USER,
    LOGOUT_USER, 
    LOADING,
    LOADING_COMPLETE,
    ADD_BUSINESS,
    POPULATE_BUSINESSES,
    VALIDATE_PAYMENT,
    ADD_REGULAR_EVENT,
    EDIT_REGULAR_EVENT,
    EDIT_IRREGULAR_EVENT,
    ADD_IRREGULAR_EVENT,
    DELETE_IRREGULAR_EVENT,
    DELETE_REGULAR_EVENT,
    SET_REGULAR_EVENTS,
    SET_IRREGULAR_EVENTS,
    CLEAR_EVENTS,
    ADD_BUSINESS_SERVICE,
    ADD_AUTHORIZED_SERVICE,
    ADD_CONNECTED_PAGE,
    REMOVE_CONNECTED_PAGE,
}