//TODO: Replace with real data, of course

const jobOpenings = [
  {
    id: 1,
    title: "Senior Accountant"
  },
  {
    id: 2,
    title: "Junior Accountant"
  },
  {
    id: 3,
    title: "Software Engineer"
  },
  {
    id: 4,
    title: "Junior Developer"
  },
  {
    id: 5,
    title: "Fullstack Architect"
  },
]

export default jobOpenings